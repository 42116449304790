@import "~antd/dist/antd.less";

@font-family: "Roboto", sans-serif;
@padding-lg: 30px;
@padding-md: 24px;
@border-radius-base: 5px;
@primary-color: #55bed2;
@info-color: #20063b;
@success-color: #55bed2;
@warning-color: #fe8833;
@error-color: #ff3341;
@link-hover-color: #55bed2;

* {
    font-family: "Poppins", sans-serif;
}

.ant-form label {
    font-size: inherit;
}

.ant-form-item {
    margin: 0;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px !important;
    overflow: hidden !important;
}

.react-tel-input .country-list {
    box-shadow: 1px 2px 10px rgb(0 0 0 / 4%) !important;
    border: 1px solid #d3d3d35e !important;
    border-radius: 8px !important;
}

.react-tel-input .country-list .search-box {
    width: calc(100% - 9px) !important;
    font-size: 13px !important;
    padding: 8px 12px !important;
    border: 1px solid #ededed !important;
    border-radius: 8px !important;
    margin: 0 !important;
    overflow: hidden !important;
}

.react-tel-input .country-list .search-emoji {
    display: none !important;
}

.modal-upload {
    .ant-modal-content {
        background: #ffffff;
        box-shadow: 0px 4px 30px rgba(0, 149, 153, 0.1);
        border-radius: 24px !important;
        width: 600px;
    }

    .ant-modal-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #22313f;
        text-align: center;
        padding-top: 15px;
    }

    .ant-modal-header {
        border-bottom-width: 0;
        border-radius: 24px;
    }

    .ant-upload.ant-upload-drag {
        border: 1px dashed #55bed2;
        border-radius: 24px;
        background-color: #fff;
    }

    .ant-modal-body {
        padding: 0 44px 32px 44px;
    }

    .ant-modal-footer {
        border-top-width: 0;
    }

    .ant-btn.ant-btn-primary {
        width: 194px;
        height: 64px;
        background: #55bed2;
        border: 1px solid #55bed2;
        border-radius: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
    }

    .ant-btn.ant-btn-default {
        display: none;
    }

    .ant-modal-footer {
        text-align: center;
        padding-bottom: 24px;
    }

    .ant-upload-btn {
        padding-top: 50px !important;
    }

    .close-button {
        position: absolute;
        top: -22px;
        right: -20px;
        z-index: 10;
    }
}

.modal-upload.hiddenButton .ant-modal-footer {
    display: none !important;
}

.ant-input-status-warning {
    border: 1px solid #dddddd !important;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
    border-color: #dddddd !important;
}

.ant-input-status-warning:focus,
.ant-select-focused .ant-select-selector,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
    box-shadow: 0 0 0 2px rgb(85 190 210 / 20%) !important;
}

.ant-picker-status-warning.ant-picker {
    border-color: #dddddd !important;
}

#popup-commentdoctor-a.ant-popover-inner {
    border-radius: 16px !important;
    max-height: 400px;
    overflow-y: scroll;
}

#popup-commentdoctor-a.ant-popover-inner::-webkit-scrollbar {
    width: 0;
}

.ant-modal-wrap {
    z-index: 1040;
}

.ant-popconfirm {
    z-index: 1050;
}

.fee-agree-on-daily .ant-input-number {
    width: 150px !important;
}

.ant-menu-submenu {
    .ant-menu-vertical {
        min-width: 250px !important;
        padding: 0;
        border-radius: 15px !important;
        max-height: 600px;
        &.ant-menu-sub {
            & > .ant-menu-item {
                height: auto !important;
                .ant-menu-title-content {
                    .ant-row {
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                        border-bottom: 1px solid #f0f0f0 !important;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        .ant-col {
                            height: 30px;
                            line-height: 30px;
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            &.notify-text-right {
                                text-align: right;
                            }
                            &.notify-case-no {
                                font-weight: 500 !important;
                                font-size: 16px !important;
                                color: #101820 !important;
                                line-height: 24px !important;
                            }
                            &.notify-time {
                                font-weight: normal;
                                font-size: 12px !important;
                                color: #727c8a !important;
                                line-height: 20px !important;
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }
        .ant-menu-item {
            margin: 0;
            &.notify-new-read {
                background-color: #f3f8fc;
            }
            &:first-child {
                padding-top: 10px;
            }
            &:last-child {
                padding-bottom: 10px;
                .ant-menu-title-content {
                    .ant-row {
                        border-bottom: none !important;
                    }
                }
            }
            &.show-more {
                background: #55bed2;
                color: #fff;
                padding-bottom: 0;
            }
        }
    }
}

.button-discard,
.button-discard:active,
.button-discard:focus,
.button-discard:hover {
    background-color: #828282;
    color: white;
    outline: none;
    border: none;
    margin-left: 8px;
    border-radius: 12px;
}

.text-popup-confirm {
    font-size: 14px;
    & > span {
        position: relative;
        top: -4px;
    }
}

.text-popup-confirm.text-popup-confirm-ok {
    border-color: #0098b0;
    color: #0098b0;
}

.tosp-discarded {
    pointer-events: none;
    position: relative;
    &:after {
        content: "";
        pointer-events: none;
        position: absolute;
        top: -4px;
        left: 0;
        bottom: 0;
        right: -8px;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: 1;
    }
}

.lc-discarded {
    pointer-events: none;
    position: relative;
    &:after {
        content: "";
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 16px;
        right: 0;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: 1;
    }
}
